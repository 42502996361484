<template>
  <component
    :is="useCombobox ? 'v-combobox' : 'v-autocomplete'"
    :items="searchItems"
    :append-icon="icon"
    :disabled="disabled"
    :loading="isLoadingSearchResults"
    item-text="nameToShow"
    item-value="user_id"
    :hide-no-data="hideNoData"
    :search-input.sync="searchQuery"
    @update:search-input="doSearch"
    @input="handleInputEvent"
    @focus="handleFocusEvent"
    @blur="handleBlurEvent"
    @change="handleChangeEvent"
    @click:append="triggerSubmit"
    name="user-search"
    class="profile-selector"
    :class="{ 'has-value': !!selectedValue, 'is-combobox': useCombobox }"
    ref="profileSelector"
    eager
    no-filter
    clearable
    return-object
    hide-details
    v-bind="$attrs"
  >
    <!-- Show loader while the data is loading -->
    <template v-slot:no-data>
      <div v-if="isLoadingSearchResults" class="text-center">
        <v-progress-circular
          :width="3"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="text-center py-2">
        No results found.
      </div>
    </template>

    <!-- Show user profile list for search results -->
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item
        ripple
        two-line
        v-bind="attrs"
        v-on="on"
        class="px-2"
        :class="listItemClass"
        :style="{ width: $refs.profileSelector.$el.clientWidth + 'px' }"
      >
        <v-list-item-action class="mr-2">
          <v-avatar
            :height="dense ? 30 : 36"
            :width="dense ? 30 : 36"
          >
            <img :src="proxyUrl(item.picture)" />
          </v-avatar>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            @{{ item.nameToShow }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ item.fullname }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <div class="text-body-2">
            {{ nFormatter(item.followers) }}
          </div>
        </v-list-item-action>
      </v-list-item>
    </template>

    <!-- Show the user profile which is selected -->
    <template v-slot:selection>
      <template v-if="!isInputActive && selectedValue">
        <span
          v-if="typeof selectedValue === 'string'"
        >
          {{ selectedValue }}
        </span>
        <profile-chip
          v-else
          :platform="platform"
          :data="selectedValue"
          :small="dense"
        />
      </template>
    </template>
  </component>
</template>

<script>
// Import child components
import { VCombobox, VAutocomplete } from 'vuetify/lib'
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')

export default {
  name: "ProfileSelector",

  components: {
    VCombobox,
    VAutocomplete,
    ProfileChip
  },

  props: {
    platform: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: false,
      default: null
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    listItemClass: {
      type: String,
      required: false,
      default: null
    },

    useCombobox: {
      type: Boolean,
      required: false,
      default: false
    },

    hideNoData: {
      type: Boolean,
      required: false,
      default: false
    },

    icon: {
      type: String,
      required: false,
      default: 'search'
    },

    changeOnSubmit: {
      type: Boolean,
      required: false,
      default: false
    },

    resetStateOnChange: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    searchQuery: "",
    isLoadingSearchResults: false,

    isInputActive: false,
    selectedValue: null,

    searchItems: [],

    // for handling debounce on type
    _timerId: null,

    // to cancel previous request if a new one is needed
    abortControllers: []
  }),

  methods: {
    /**
     * Make API request to fetch the select options for autocomplete
     *
     * @returns {void}
     */
    doSearch() {
      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        // if input is empty, don't continue
        if (!this.searchQuery) {
          // reset the search results
          this.searchItems = []

          return
        }

        // cancel pending network requests
        for (const item of this.abortControllers) {
          item.controller.abort()
        }

        // make network requests in vuex store
        const id = Symbol()
        const controller = new AbortController()

        // push this controller to the list
        this.abortControllers.push({ id, controller, query: this.searchQuery })

        // show the loader while request is being made
        this.isLoadingSearchResults = true

        try {
          const queryParams = new window.URLSearchParams({
            platform: this.platform,
            type: this.type,
            q: this.searchQuery
          })

          const response = await axios({
            url: "/api/filters/users/?" + queryParams,
            signal: controller.signal
          })

          // the API responds like
          this.searchItems.splice(
            0,
            this.searchItems.length,
            ...response.data
              .map((item) => ({
                ...item,
                idToQuery: item.user_id || item.username || item.custom_name || '',
                nameToShow: item.username || item.custom_name || item.handle || item.fullname,

                // Since audience lookalike filter no longer supports user_id, only username or custom_name, we'd have to make some changes here
                lookalikeId: item.username || item.custom_name || item.user_id || '',

                // The value to use in social sentiments etc. kind of selectors
                value: item.username || item.handle || item.custom_name || item.user_id || '',
              }))
          )
        } catch (error) {
          // log using the helper function
          logger({ type: "Network Error", error })
        } finally {
          this.isLoadingSearchResults = false

          // remove the controller from cancellable array
          const index = this.abortControllers.findIndex((item) => item.id === id)
          this.abortControllers.splice(index, 1)
        }
      }, 500)
    },

    /**
     * Handle input event
     *
     * @returns {void}
     */
    handleInputEvent() {
      if (this.resetStateOnChange) {
        this.searchQuery = ""
        this.searchItems = []
      }

      // Emit the event
      this.$emit("input", this.searchQuery)
    },

    /**
     * Handle focus event
     *
     * @returns {void}
     */
    handleFocusEvent() {
      this.isInputActive = true

      // Emit the event
      this.$emit("focus")
    },

    /**
     * Handle blur event
     *
     * @returns {void}
     */
    handleBlurEvent() {
      this.isInputActive = false

      // Emit the event
      this.$emit("blur")
    },

    /**
     * Handle change event
     *
     * @param {Object|String} value
     * @returns {void}
     */
    handleChangeEvent(value) {
      this.selectedValue = value
      this.$refs.profileSelector.$el.querySelector("input").blur()

      // If the changeOnSubmit is false
      if (this.changeOnSubmit === false) {
        this.$emit("change", value)
      }
    },

    /**
     * Trigger submit event
     *
     * @returns {void}
     */
    triggerSubmit() {
      // If we should emit change on submit
      // And we have a selected value
      if (this.changeOnSubmit && this.selectedValue) {
        this.$emit("change", this.selectedValue)
      }
    }
  }
}
</script>

<style lang="stylus">
.profile-selector
  .v-select__selections
    display flex
    flex-wrap nowrap

  &.v-autocomplete.v-select--is-menu-active
    .v-input__icon--append
      .v-icon
        transform none !important

  &.is-combobox.has-value
    input
      display none
</style>
